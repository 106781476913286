import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationTime',
  standalone: true
})
export class DurationTimePipe implements PipeTransform {
  transform(startDate: string, lang: string = 'ar'): unknown {
    let lastDateFormat: any = new Date();
    let startDateFormat: any = new Date(startDate);
    let diffMs = lastDateFormat - startDateFormat; // milliseconds
    let diffDays = Math.floor(diffMs / 86400000); // days
    let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math.floor(((diffMs % 86400000) % 3600000) / 60000); // minutes
    let diffSecs = Math.round((((diffMs % 86400000) % 3600000) % 60000) / 1000); // minutes
    const dateObject = {
      days: diffDays,
      hours: diffHrs,
      minutes: diffMins,
      seconds: diffSecs,
    };
    return dateObject.days >= 1
      ? dateObject.days && lang == 'ar'
        ? dateObject.days + ' يوم '
        : dateObject.days + ' Day '
      : dateObject.hours >= 1
        ? dateObject.hours && lang == 'ar'
          ? dateObject.hours +
          (dateObject.hours > 1 ? ' ساعات ' : ' ساعة ') +
          (dateObject.minutes > 1 ? ',' + dateObject.minutes + ' دقيقة ' : '')
          : dateObject.hours +
          (dateObject.hours > 1 ? ' Hours ' : ' Hour ') +
          (dateObject.minutes > 1 ? ',' + dateObject.minutes + ' Min ' : '')
        : dateObject.minutes >= 1
          ? dateObject.minutes && lang == 'ar'
            ? dateObject.minutes + ' دقيقة '
            : dateObject.minutes + ' Min '
          : '';
  }
}
