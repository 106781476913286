export interface User {
  id?: number;
  username: string;
  email: string;
  nid?: null;
  password?: string;
  phone?: string;
  photoUrl?: null;
  additionalData?: AdditionalData;
  verified?: boolean;
  banned?: boolean;
  banReason?: null;
  banUntil?: null;
  typeId?: number;
  createdAt?: string;
  updatedAt?: string;
  roleId?: number;
  isDeleted?: boolean;
  isFinishedAdditionalStep?: boolean;
  isDev?: boolean;
  isDisabled?: boolean;
}

export interface AdditionalData {
  bank?: string;
  city?: string;
  iban?: string;
  gender?: string;
  street?: string;
  district?: string;
  lastName?: string;
  birthDate?: string;
  firstName?: string;
  fatherName?: string;
  postalCode?: string;
}

export enum Roles {
  superAdmin = 1,
  user = 2,
  superAdminTitle = "superAdmin",
  adminTitle = "admin",
  viewerTitle = "readOnlyAdmin",
}
