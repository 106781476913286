export const environment = {
  production: true,
  api_url: "https://lawhatyapistaging.kortobaa.net/",
  name: "stag",
  firebase: {
    apiKey: "AIzaSyDMh8L5rOMV7T0WqUZ-xUV7HRgxcHlh854",
    authDomain: "lawhaty-staging.firebaseapp.com",
    projectId: "lawhaty-staging",
    storageBucket: "lawhaty-staging.appspot.com",
    messagingSenderId: "174862959122",
    appId: "1:174862959122:web:c14c2b156320fe305c4ecb",
    vapidKey: "BK9N9llxF2Cjls3qgKbmmzmRYHPyWoSw5QQ3Lz3q0LBD_Z7PV1C51eXS4CEsxiP8OFIom8e_BEr7Wv-kBTn5BLU",
  },
};
