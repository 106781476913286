import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'checkLanguage',
  standalone: true
})
export class checkLanguagePipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    return /[\u0600-\u06FF]/.test(value);
  }
}
