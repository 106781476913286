import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { ErrorHandlingService } from "./error-handling.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Roles } from "src/app/shared/interfaces/user";

@Injectable()
export class GlobalErrorHandler implements HttpInterceptor {
  constructor(
    private errorHandlingService: ErrorHandlingService,
    private _auth: AuthService,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        error: (httpError) => {
          //destruct statuscode & error message
          const { statusCode, message } = httpError?.error?.error
            ? httpError?.error?.error
            : { statusCode: null, message: null };
          if (httpError.status == 0) {
            return this.errorHandlingService.setError({
              status: httpError.status,
              title: "لقد فقدت الاتصال بالانترنت!",
              message: "فضلاً قم بالتحقق من اتصالك بالشبكة \n ثم قم بتحديث الصفحة",
            });
          } else if (
            httpError.status === 401 &&
            !httpError.url.includes("login") &&
            this._auth.user.currentUser["roles"][0] == Roles.viewerTitle
          ) {
            return this._snackBar.open(" أنت غير مصرح لك بذلك", "", {
              panelClass: "error-snackbar",
              duration: 1500,
            });
          } else if (httpError.status === 401 && !httpError.url.includes("login")) {
            this._snackBar.open("غير مصرح لك..الرجاء إعادة تسجيل الدخول في حالة وجود خطأ", "", {
              panelClass: "error-snackbar",
              duration: 1500,
            });
            this._auth.user.logout();
            localStorage.clear();
            sessionStorage.clear();
            this._router.navigate(["/login"]);
          } else if (httpError.status == 422) {
            return this._snackBar.open("البيانات المدخلة غير صحيحة", "", {
              panelClass: "error-snackbar",
              duration: 1500,
            });
          } else if (httpError.status == 500) {
            return this._snackBar.open("عذرا وقع خطأ بالخادم", "", {
              panelClass: "error-snackbar",
              duration: 1500,
            });
          } else if (httpError.status == 404 && httpError.url.includes("undefined.json")) {
            return;
          }
          //  else if (httpError.status != 400 && httpError.status != 401) {
          //   return this.errorHandlingService.setError({
          //     status: httpError.status,
          //     title: "لقد حدث خطأ !",
          //     message: "عفواً، لقد حدث خطأ ما عند تحميل الصفحة \n  من فضلك حاول مرة آخرى.",
          //   });
        },
      })
    );
  }
}
