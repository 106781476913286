import { Component, ElementRef, Input, Renderer2, ViewChild } from "@angular/core";
import { NotificationService } from "./notification.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { FormsModule } from "@angular/forms";
import { KNgDataTableModule } from "@kortobaa-front/k-ng-datatable";
import { DatePipePipe } from "src/app/shared/pipes/date-pipe.pipe";
import { PlatformService } from "src/app/core/services/platform.service";

@Component({
  selector: "app-notification",
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    MatSlideToggleModule,
    FormsModule,
    KNgDataTableModule,
    DatePipePipe,
  ],
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent {
  @ViewChild("openNotify") openNotify!: ElementRef;
  toggleOn: boolean = false;
  notifySub$!: Subscription;
  notifyAllSub$!: Subscription;
  notifyAllCountSub$!: Subscription;
  notifyCountSub$!: Subscription;
  notifySeenSub$!: Subscription;
  notifyOpenSub$!: Subscription;
  notifyNewSub$!: Subscription;
  notifyLoadSub$!: Subscription;
  notifyRouteSub$!: Subscription;
  notifications: any = [];
  notificationsCount: any = [];
  infiniteScrollCounter: number = 0;
  hasMoreNotifications: boolean = true;
  notSeenNotifySub$!: Subscription;
  @Input() hasUnseenNotification!: number;
  loading: boolean = false;
  notificationAllCount!: number;
  notifyRedirect: { value: string; route: string }[] = [
    {
      value: "plate",
      route: "plates-ads/ads-info",
    },
    {
      value: "mobileNum",
      route: "mobile-num-ads/ads-info",
    },
    {
      value: "socialAccount",
      route: "social-account-ads/ads-info",
    },
    {
      value: "domain",
      route: "domain-ads/ads-info",
    },
  ];

  secondaryColor = "var(--secondary-color)";

  constructor(
    public notification: NotificationService,
    private _router: Router,
    private renderer: Renderer2,
    public _platForm: PlatformService
  ) {
    this.notifySub$ = this.notification.getCloseDialog().subscribe((res) => {
      if (this.openNotify?.nativeElement) {
        if (res == "close") {
          this.openNotify.nativeElement.style.display = "none";
          this.openNotify.nativeElement.children[1].style.width = "0";
          document.body.classList.remove("hidden");
        } else {
          document.body.classList.add("hidden");
          this.openNotify.nativeElement.style.display = "block";
        }
      }
    });
    this.notifySeenSub$ = this.notification.getSeenNotification().subscribe((data) => {
      if (!data)
        this.notifications.forEach((notification: any) => {
          notification.seen = true;
        });
    });
    this.renderer.listen("window", "click", (e: any) => {
      if (!this.openNotify.nativeElement.children[1].contains(e.target) && e.target?.id != "notify") {
        this.notification.emitCloseDialog("close");
        document.body.classList.remove("hidden");
        this.toggleOn = false;
      }
    });
    this.notifyOpenSub$ = this.notification.getOpenDialog().subscribe((data) => {
      this.loading = true;
      this.notifyAllCountSub$ = this.notification.getNotificationsCounter().subscribe((data: any) => {
        this.notificationAllCount = data ? data.count : 0;
      });
      this.notifyAllSub$ = this.notification.getNotificationsScroll(5, 0).subscribe((data) => {
        this.loading = false;
        this.notifications = data;
        this.infiniteScrollCounter = 5;
        this.hasMoreNotifications = true;
      });
      this.notifyCountSub$ = this.notification
        .getNotificationsCount()
        .subscribe((data) => (this.notificationsCount = data));
    });
    this.notifyNewSub$ = this.notification.getNewNotification().subscribe((data) => {
      this.notification.getNotificationsScroll(5, this.infiniteScrollCounter).subscribe((data) => {
        this.notifications = data;
        this.infiniteScrollCounter = 5;
        this.hasMoreNotifications = true;
      });
      this.notification.getNotificationsCount().subscribe((data) => {
        this.notificationsCount = data;
        this.notification.emitSeenNotification();
      });
    });
  }

  ngOnInit(): void {}

  // redirect to page
  goTo(app: string, id: number, notificationId: number) {
    // document.body.classList.remove("hidden");
    this.notifyRouteSub$ = this.notification.updateNotification(notificationId).subscribe((data: any) => {
      if (app != "message") {
        this._router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
          this.notifyRedirect.map((notify) => {
            if (app == notify.value)
              this._router.navigateByUrl(
                `${
                  notify.value == "plate"
                    ? notify.route + `/${id}`
                    : notify.value == "mobileNum"
                    ? notify.route + `/${id}`
                    : notify.value == "socialAccount"
                    ? notify.route + `/${id}`
                    : notify.value == "domain"
                    ? notify.route + `/${id}`
                    : notify.route
                }`
              );
          });
        });
      }
      this.notification.emitCloseDialog("close");
      this.toggleOn = false;
      this.notification.emitSeenNotification();
    });
  }

  ngAfterContentChecked() {
    if (
      (this.notifications.length < this.notificationAllCount && !this.toggleOn) ||
      (this.notifications.length < this.notificationsCount.count && this.toggleOn)
    ) {
      this.hasMoreNotifications = true;
    } else {
      this.hasMoreNotifications = false;
    }
  }

  // load more notification
  loadMore() {
    if (this.toggleOn === true) {
      // this.infiniteScrollCounter = 5;
      this.getNotSeenNotification();
    } else {
      this.notifyLoadSub$ = this.notification
        .getNotificationsScroll(5, this.infiniteScrollCounter)
        .subscribe((data: any) => {
          if (data.length > 0) {
            this.notifications = this.getUniqueObjects([...this.notifications, ...data]);
            this.infiniteScrollCounter += 5;
          } else {
            this.hasMoreNotifications = false;
          }
        });
    }
  }

  // close notification
  close() {
    this.notification.emitCloseDialog("close");
    this.toggleOn = false;
    document.body.classList.remove("hidden");
    this.notification.emitSeenNotification();
  }

  // toggle icon
  toggle(value: boolean) {
    this.toggleOn = value;
    this.hasMoreNotifications = true;
    this.infiniteScrollCounter = 0;
    this.notification.getNotificationsScroll(5, this.infiniteScrollCounter).subscribe((data: any) => {
      this.notifications = this.getUniqueObjects(
        value
          ? data.filter((notify: any) => {
              return notify.seen == false;
            })
          : [...data]
      );
      if (value === true) {
        this.infiniteScrollCounter = 0;
        this.getNotSeenNotification();
      }
    });
  }

  // get not seen notification
  getNotSeenNotification() {
    this.notSeenNotifySub$ = this.notification
      .getNotificationSeen(5, this.infiniteScrollCounter)
      .subscribe((data: any) => {
        if (data.length > 0) {
          this.notifications = this.getUniqueObjects([...this.notifications, ...data]);
          this.infiniteScrollCounter += 5;
        }
        if (this.notificationsCount.count == this.notifications.length) {
          this.hasMoreNotifications = false;
        }
      });
  }

  getUniqueObjects(array: any[]) {
    const seen = new Set();
    return array.filter((item) => {
      const key = item["id"];
      if (!seen.has(key)) {
        seen.add(key);
        return true;
      }
      return false;
    });
  }
  ngOnDestroy(): void {
    if (this.notifySub$) this.notifySub$.unsubscribe();
    if (this.notifyAllSub$) this.notifyAllSub$.unsubscribe();
    if (this.notifyCountSub$) this.notifyCountSub$.unsubscribe();
    if (this.notifySeenSub$) this.notifySeenSub$.unsubscribe();
    if (this.notifyOpenSub$) this.notifyOpenSub$.unsubscribe();
    if (this.notifyNewSub$) this.notifyNewSub$.unsubscribe();
    if (this.notSeenNotifySub$) this.notSeenNotifySub$.unsubscribe();
    if (this.notifyLoadSub$) this.notifyLoadSub$.unsubscribe();
    if (this.notifyRouteSub$) this.notifyRouteSub$.unsubscribe();
    if (this.notifyAllCountSub$) this.notifyAllCountSub$.unsubscribe();
  }
}
