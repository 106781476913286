import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notifictionNumber',
  standalone: true
})
export class NotifictionNumberPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    if (value >= 100) {
      return "+99";
    }
    else{
      return value.toString();
    }
  }

}
