import { Route } from "@angular/router";
import { AuthGuard } from "../guards/auth.guard";
import { SuperAdminGuard } from "../guards/superAdmin.guard";

export const settingsroutes: Route[] = [
  // setting
  {
    path: "settings",
    loadComponent: () =>
      import("../../features/settings/pages/setting-page/setting-page.component").then(
        (mod) => mod.SettingPageComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard, SuperAdminGuard],
  },
  {
    path: "settings/admins",
    loadComponent: () =>
      import("../../features/settings/pages/admins-page/admins-page.component").then((mod) => mod.AdminsPageComponent),
    pathMatch: "full",
    canActivate: [AuthGuard, SuperAdminGuard],
  },
  {
    path: "settings/bank-info",
    loadComponent: () =>
      import("../../features/settings/pages/bank-details/bank-details.component").then(
        (mod) => mod.BankDetailsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard, SuperAdminGuard],
  },
  {
    path: "settings/user-banks",
    loadComponent: () =>
      import("../../features/settings/pages/banks-page/banks-page.component").then((mod) => mod.BanksPageComponent),
    pathMatch: "full",
    canActivate: [AuthGuard, SuperAdminGuard],
  },
  {
    path: "settings/company-banks",
    loadComponent: () =>
      import("../../features/settings/pages/banks-list/banks-list.component").then((mod) => mod.BanksListComponent),
    pathMatch: "full",
    canActivate: [AuthGuard, SuperAdminGuard],
  },
  {
    path: "settings/extra-services",
    loadComponent: () =>
      import("../../features/settings/pages/services-tabs/services-tabs.component").then(
        (mod) => mod.ServicesTabsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard, SuperAdminGuard],
  },
  {
    path: "settings/extra-services/:name",
    loadComponent: () =>
      import("../../features/settings/pages/service-page/service-page.component").then(
        (mod) => mod.ServicePageComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard, SuperAdminGuard],
  },
];
