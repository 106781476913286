import { CommonModule } from "@angular/common";
import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-confirm-dialog",
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule],
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent {
  @ViewChild("uploadFilesInput") uploadFilesInput!: ElementRef;
  attachments: any[] = [];
  constructor(
    public _dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar
  ) {
    _dialogRef.disableClose = true;
  }

  accept(boolean: boolean = true) {
    let body: any = {
      boolean: boolean,
    };
    this._dialogRef.close(body);
  }

  //trigger the file input by click attach icon
  triggerUploadFiles(): void {
    this.uploadFilesInput.nativeElement.click();
  }

  uploadFiles(event: any, attachments: any[], maxSize: number, maxLength: number): void {
    let files = event.target.files;

    let isLengthValid = attachments.length + [...files].length <= maxLength;
    let isSizeValid = [...files]?.every((file, index) => {
      return file.size <= maxSize;
    });

    if (!isLengthValid) {
      this._snackBar.open(`حد أقصى ${maxLength} ملفات`, "X", {
        panelClass: "error-snackbar",
        // verticalPosition: "bottom",
        // duration: 1500,
      });
    }

    if (!isSizeValid) {
      this._snackBar.open("الملف اكبر من الحد المسموح", "X", {
        panelClass: "error-snackbar",
        // verticalPosition: "bottom",
      });
    }

    if (isSizeValid && isLengthValid) {
      attachments?.push(...files);
      this._dialogRef.close({
        boolean: true,
        attachments: attachments,
      });
    }

    this.uploadFilesInput.nativeElement.value = "";
  }
}
