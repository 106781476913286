import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "phoneCode",
  standalone: true,
})
export class PhoneCodePipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    if (!value?.includes("+")) {
      value = "+" + value;
    }
    value =
      value.substr(0, 4) + " " + value.substr(4, 2) + " " + value.substr(6, 3) + " " + value.substr(9, value.length);
    return value;
  }
}
