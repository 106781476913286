import { Component, Inject } from "@angular/core";
import { CommonModule, DOCUMENT } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { LayoutComponent } from "./layout/layout.component";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [CommonModule, LayoutComponent],
  templateUrl: "./app.component.html",
  styles: [],
})
export class AppComponent {
  title = "Admin Dashboard";
  currentLang: string = "ar";
  constructor(
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.setLanguage();
  }

  // set language
  setLanguage() {
    this.currentLang = localStorage.getItem("lang") || "ar";
    this.translateService.setDefaultLang(this.currentLang);
    this.translateService.use(this.currentLang);
    let htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    htmlTag.dir = this.currentLang === "ar" ? "rtl" : "ltr";
    htmlTag.lang = this.currentLang === "ar" ? "ar" : "en";
    localStorage.setItem("lang", this.currentLang);
  }
}
