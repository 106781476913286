import { Platform } from "@angular/cdk/platform";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PlatformService {
  public screenWidth: number = window.innerWidth;
  android!: boolean;
  ios!: boolean;

  constructor(private platform: Platform) {
    this.android = this.platform.ANDROID;
    this.ios = this.platform.IOS;
  }

  getLanguage() {
    return localStorage.getItem("lang") || "ar";
  }
}
