import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ErrorMessage } from "src/app/core/error-handler/errorMessage";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { getInternetOfflineImage } from "./internet-offline-image";

@Component({
  selector: "app-reload-page",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./reload-page.component.html",
  styleUrls: ["./reload-page.component.scss"],
})
export class ReloadPageComponent {
  @Input() errorMessage?: ErrorMessage;
  formattedText: SafeHtml | undefined;
  base64String?: string;

  constructor(private sanitizer: DomSanitizer) {
    this.errorMessage && this.errorMessage.status == 0
      ? (this.base64String = getInternetOfflineImage())
      : (this.base64String = "assets/images/server-down.png");
  }
  isOnline: boolean = navigator.onLine;
  imagePath?: string;

  ngOnInit() {
    // handle message to add new line
    if (this.errorMessage) {
      this.formattedText = this.sanitizer.bypassSecurityTrustHtml(this.errorMessage?.message.replace(/\n/g, "<br>"));
      this.errorMessage.status == 0 ? (this.base64String = getInternetOfflineImage()) : "assets/images/server-down.png";
    }
  }

  // reload page
  reloadPage() {
    location.reload();
  }
}
