import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Login,
  LoginService,
  RegisterService,
  ResetPassowrdData,
  UpdatePasswordData,
  UserService,
} from '@kortobaa-front/authentication';

@Injectable()
export class AuthService {
  constructor(
    private _login: LoginService,
    public user: UserService,
    public registerService: RegisterService,
    private _http: HttpClient,
  ) { }

  // get token
  userToken() {
    return {
      headers: { Authorization: `Bearer ${this.user.getToken()}` },
    };
  }

  //login user
  async login(body: Login) {
    return await this._login.login(body, { url: 'admin/login' });
  }

  //forget password
  async forgetPass(body: any) {
    return await this.registerService.resetPassword(body, {
      url: 'admin/reset-password',
    });
  }

  // verify token to add new password
  verifyResetPassword(token: string, uuid: string) {
    return this._http.post(
      `${environment.api_url}admin/verify/reset-change-password`,
      { token, uuid },
    );
  }

  // reset password to add new password
  resetPassword(token: string, uuid: string, newPassword: string) {
    return this._http.post(
      `${environment.api_url}admin/reset-change-password`,
      { token, uuid, newPassword },
    );
  }


  // change password
  async updateUserPassword(passwordData: UpdatePasswordData) {
    await this.user.updatePassword(passwordData);
  }
}
