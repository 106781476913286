import { Component, ElementRef, Renderer2, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router, RouterOutlet } from "@angular/router";
import { HeaderComponent } from "./components/header/header.component";
import { AuthService } from "../auth/services/auth.service";
import { SideMenuComponent } from "./components/side-menu/side-menu.component";
import { MatIconModule } from "@angular/material/icon";
import { Location } from "@angular/common";
import { ReloadPageComponent } from "../shared/components/reload-page/reload-page.component";
import { ErrorHandlingService } from "../core/error-handler/error-handling.service";
import { ErrorMessage } from "../core/error-handler/errorMessage";
import { FirebaseService } from "../core/services/firebase.service";
import { UserService } from "@kortobaa-front/authentication";
import { NotificationService } from "./components/notification/notification.service";
import { NotificationComponent } from "./components/notification/notification.component";

@Component({
  selector: "app-layout",
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HeaderComponent,
    SideMenuComponent,
    MatIconModule,
    ReloadPageComponent,
    NotificationComponent,
  ],
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent {
  sideNavToggleBtn: boolean = JSON.parse(localStorage.getItem("sideNavToggleBtn") || "false");
  route!: any;
  subMenu: boolean = true;
  errorMessage: ErrorMessage | undefined;
  hasUnseenNotification!: number;
  showSideNav: boolean = false;
  @ViewChild("sideNav") sideNav!: ElementRef;
  userLogin = !!this.auth.user.currentUser && !!localStorage.getItem("Token") && !!localStorage.getItem("UserProfile");

  constructor(
    public auth: AuthService,
    private _router: Router,
    location: Location,
    private errorHandlingService: ErrorHandlingService,
    private _firebase: FirebaseService,
    private _user: UserService,
    private _notification: NotificationService,
    private renderer: Renderer2
  ) {
    this._router.events.subscribe((val) => {
      this.watchTokenValidity();
      if (location.path() != "") {
        this.route = location
          .path()
          .split("/")
          .filter((ele) => {
            return ele != "";
          });
        if (this.route[0] != "home") this.route = ["home", ...this.route];
      }
    });

    this._user.checkUserValid().subscribe((data: any) => {
      if (data) {
        this._firebase.requestToken();
        this._firebase.listenOnNotification();
      }
    });
    this._notification.getUserIsAuthenticated().subscribe((data: any) => {
      this._firebase.requestToken();
      this._firebase.listenOnNotification();
    });
    // close side nav when click out side in responsive
    this.renderer.listen("window", "click", (e: any) => {
      if (this.sideNav && !this.sideNav.nativeElement.children[0].contains(e.target) && e.target?.id != "sideNavIcon") {
        this.showSideNav = false;
      }
    });
  }

  ngOnInit() {
    this.errorHandlingService.errorMessage$.subscribe((message) => {
      this.errorMessage = message;
    });
  }

  ngAfterContentChecked() {
    this.userLogin =
      !!this.auth.user.currentUser && !!localStorage.getItem("Token") && !!localStorage.getItem("UserProfile");
  }

  // check token
  watchTokenValidity() {
    let token = localStorage.getItem("Token");
    if (token) {
      const decode = JSON.parse(atob(token.split(".")[1]));
      if (decode.exp * 1000 < new Date().getTime()) {
        this.auth.user.logout();
        localStorage.clear();
        sessionStorage.clear();
        this._router.navigateByUrl("/login");
      }
    }
  }

  // close sub menu when click out side side bar
  toggleSubMenu(e: any) {
    this.subMenu = e;
  }

  // toggle Sidebar nav btn and save it to local storage
  toggleSideNav(value: boolean) {
    this.sideNavToggleBtn = value;
    this.subMenu = false;
    localStorage.setItem("sideNavToggleBtn", JSON.stringify(value));
  }
}
