import { Route } from "@angular/router";
import { AuthGuard } from "../guards/auth.guard";

export const mobileAdsroutes: Route[] = [
  // mobile-num-ads
  {
    path: "mobile-num-ads/ads-pending",
    loadComponent: () =>
      import("../../features/mobile-num/pages/pending-ads/pending-ads.component").then(
        (mod) => mod.PendingAdsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "mobile-num-ads/ads-rejected-deleted",
    loadComponent: () =>
      import("../../features/mobile-num/pages/rejected-deleted-ads/rejected-deleted-ads.component").then(
        (mod) => mod.RejectedDeletedAdsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "mobile-num-ads/ads-approved",
    loadComponent: () =>
      import("../../features/mobile-num/pages/approved-ads/approved-ads.component").then(
        (mod) => mod.ApprovedAdsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "mobile-num-ads/ads-suspended",
    loadComponent: () =>
      import("../../features/mobile-num/pages/suspended-ads/suspended-ads.component").then(
        (mod) => mod.SuspendedAdsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "mobile-num-ads/ads-payment-pending",
    loadComponent: () =>
      import("../../features/mobile-num/pages/pending-payment-ads/pending-payment-ads.component").then(
        (mod) => mod.PendingPaymentAdsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "mobile-num-ads/ads-confirmation-pending",
    loadComponent: () =>
      import("../../features/mobile-num/pages/pending-confirmation-ads/pending-confirmation-ads.component").then(
        (mod) => mod.PendingConfirmationAdsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "mobile-num-ads/ads-fund-transfer-pending",
    loadComponent: () =>
      import("../../features/mobile-num/pages/pending-fund-transfer-ads/pending-fund-transfer-ads.component").then(
        (mod) => mod.PendingFundTransferAdsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "mobile-num-ads/ads-ended",
    loadComponent: () =>
      import("../../features/mobile-num/pages/ended-ads/ended-ads.component").then((mod) => mod.EndedAdsComponent),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "mobile-num-ads/all-ads",
    loadComponent: () =>
      import("../../features/mobile-num/pages/all-ads/all-ads.component").then((mod) => mod.AllAdsComponent),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "mobile-num-ads/ads-info/:id",
    loadComponent: () =>
      import("../../features/mobile-num/pages/adv-view/adv-view.component").then((mod) => mod.AdvViewComponent),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
];
