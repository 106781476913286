import { Route } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { NotAuthGuard } from "./guards/notAuth.guard";
import { ResetPasswordGuard } from "./guards/reset-password.guard";
import { ForgetPasswordOtpGuard } from "./guards/forget-password-otp.guard";
import { SuperAdminGuard } from "./guards/superAdmin.guard";
import { platesAdsroutes } from "./routes/plates-ads.routes";
import { mobileAdsroutes } from "./routes/mobile-ads.routes";
import { socialAdsroutes } from "./routes/social-ads.routes";
import { settingsroutes } from "./routes/settings.routes";
import { domainAdsroutes } from "./routes/domain-ads.routes";

export const routes: Route[] = [
  { path: "", redirectTo: "home", pathMatch: "full" },

  // authentication
  {
    path: "login",
    pathMatch: "full",
    loadComponent: () => import("../auth/pages/auth-frame/auth-frame.component").then((mod) => mod.AuthFrameComponent),
    data: { current: "login" },
    canActivate: [NotAuthGuard],
  },
  {
    path: "forget-password",
    pathMatch: "full",
    loadComponent: () => import("../auth/pages/auth-frame/auth-frame.component").then((mod) => mod.AuthFrameComponent),
    data: { current: "forget-password" },
    canActivate: [NotAuthGuard],
  },
  {
    path: "forget-password-otp",
    loadComponent: () => import("../auth/pages/auth-frame/auth-frame.component").then((mod) => mod.AuthFrameComponent),
    pathMatch: "full",
    data: {
      current: "forget-password-otp",
      prevRoute: "forget-password",
    },
    canActivate: [ForgetPasswordOtpGuard],
  },
  {
    path: "reset-password",
    loadComponent: () => import("../auth/pages/auth-frame/auth-frame.component").then((mod) => mod.AuthFrameComponent),
    pathMatch: "full",
    data: {
      current: "reset-password",
      prevRoute: "forget-password-otp",
    },
    canActivate: [ResetPasswordGuard],
  },
  // home
  {
    path: "home",
    loadComponent: () => import("../home/pages/home/home.component").then((mod) => mod.HomeComponent),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  // user
  {
    path: "users",
    loadComponent: () =>
      import("../features/users/pages/users-page/users-page.component").then((mod) => mod.UsersPageComponent),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "users/user-info/:id",
    loadComponent: () =>
      import("../features/users/pages/user-info/user-info.component").then((mod) => mod.UserInfoComponent),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  // cms
  {
    path: "pages/:id",
    loadComponent: () =>
      import("../features/CMS/pages/cms-page/cms-page.component").then((mod) => mod.CMSPageComponent),
    pathMatch: "full",
    canActivate: [AuthGuard, SuperAdminGuard],
  },
  // payments
  {
    path: "payments",
    loadComponent: () =>
      import("../features/paymnets/pages/payments-report/payments-report.component").then(
        (mod) => mod.PaymentsReportComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  ...platesAdsroutes,
  ...mobileAdsroutes,
  ...socialAdsroutes,
  ...domainAdsroutes,
  ...settingsroutes,

  { path: "**", redirectTo: "home" },
];
