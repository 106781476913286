import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { UserService } from "@kortobaa-front/authentication";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(
    private _http: HttpClient,
    private _user: UserService
  ) {}

  //open notification dialog

  openNotificationDialog = new EventEmitter();

  emitOpenDialog() {
    this.openNotificationDialog.emit();
  }

  getOpenDialog() {
    return this.openNotificationDialog;
  }

  //close notification dialog

  closeNotificationDialog = new EventEmitter();

  emitCloseDialog(body: any) {
    this.closeNotificationDialog.emit(body);
  }

  getCloseDialog() {
    return this.closeNotificationDialog;
  }

  //get new notification

  pushNotification = new EventEmitter();

  getNewNotification() {
    return this.pushNotification;
  }

  emitNewNotificationEvent(body: any) {
    this.pushNotification.emit(body);
  }

  //unseen notifications

  seenNotification = new EventEmitter();

  emitSeenNotification() {
    this.seenNotification.emit();
  }

  getSeenNotification() {
    return this.seenNotification;
  }

  //user is authenticatedZ
  userIsAuthenticated = new EventEmitter();

  emitUserIsAuthenticated() {
    this.userIsAuthenticated.emit();
  }

  getUserIsAuthenticated() {
    return this.userIsAuthenticated;
  }

  //notification endpoint
  getNotifications() {
    return this._http.get(`${environment.api_url}my-notifications`, this.userToken());
  }

  getNotificationsCounter() {
    let options = {
      headers: { Authorization: `Bearer ${this._user.getToken()}` },
      params: { all: 1 },
      body: {
        platform: "web",
        uuid: "123456789",
      },
    };
    return this._http.get(`${environment.api_url}notifications-counter`, options);
  }

  getNotificationSeen(limit: number, skip: number) {
    return this._http.get(
      `${environment.api_url}my-notifications?filter={"where": {"seen": false},"take":${limit},"skip":${skip}}`,
      this.userToken()
    );
  }

  getNotificationsScroll(limit: number, skip: number) {
    return this._http.get(
      `${environment.api_url}my-notifications?filter={"take":${limit} ,"skip":${skip}}`,
      this.userToken()
    );
  }

  getNotificationsCount() {
    return this._http.get(`${environment.api_url}notifications-counter`, this.userToken());
  }

  updateNotificationToSeen() {
    return this._http.get(`${environment.api_url}notifications-seen`, this.userToken());
  }

  updateNotification(id: number) {
    return this._http.get(`${environment.api_url}notification-seen/${id}`, this.userToken());
  }

  userToken() {
    return {
      headers: { Authorization: `Bearer ${this._user.getToken()}` },
      body: {
        platform: "web",
        uuid: "123456789",
      },
    };
  }
}
