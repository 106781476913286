import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthService } from "src/app/auth/services/auth.service";
import { MatIconModule } from "@angular/material/icon";
import { Router, RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription, map } from "rxjs";
import { NotificationService } from "../notification/notification.service";
import { UserService } from "@kortobaa-front/authentication";
import { PlatformService } from "src/app/core/services/platform.service";
import { checkLanguagePipe } from "src/app/shared/pipes/checkLanguage.pipe";
import { NotifictionNumberPipe } from "src/app/shared/pipes/notifiction-number.pipe";

@Component({
  selector: "app-header",
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule, RouterLink, checkLanguagePipe, NotifictionNumberPipe],
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  @Input() route: any = [];
  @Input() sideNavToggleBtn: boolean = false;
  navFlag: string = "close";
  hasUnseenNotification!: number;
  @Output() sideNavToggleBtnRes = new EventEmitter<boolean>();

  constructor(
    public auth: AuthService,
    private router: Router,
    private _notification: NotificationService,
    private _user: UserService,
    public _platForm: PlatformService
  ) {
    this._notification.emitCloseDialog(this.navFlag);

    this._notification.getCloseDialog().subscribe((data) => {
      this.navFlag = data;
    });
  }

  ngOnInit(): void {
    this._user.checkUserValid().subscribe((data: any) => {
      if (data) {
        this._notification.getNotificationsCount().subscribe((data: any) => {
          this.hasUnseenNotification = data.count;
        });
      }
    });
    this._notification.getUserIsAuthenticated().subscribe((data: any) => {
      this._notification.getNotificationsCount().subscribe((data: any) => {
        this.hasUnseenNotification = data.count;
      });
    });
    this._notification.getSeenNotification().subscribe((data: any) => {
      this._notification.getNotificationsCount().subscribe((data: any) => {
        this.hasUnseenNotification = data.count;
      });
    });
  }

  // redirect to
  redirectTo(route: string) {
    // if (route == 'consultant-data') {
    //   this._certifiedConsultant.emitEvent(false);
    //   // this.router.navigate([]);
    //   return;
    // }
    this.router.navigate([route]);
  }

  // open notification
  openNotification(value: any) {
    this.navFlag = this.navFlag == "close" ? "open" : "close";
    this._notification.emitCloseDialog(this.navFlag);
    if (this.navFlag == "open") {
      // this.notifySub$ = this._notify.updateNotificationToSeen().subscribe((data) => {
      //   this.hasUnseenNotification = false;
      //   this._notify.emitSeenNotification();
      // });
      this._notification.emitOpenDialog();
    }
  }
}
