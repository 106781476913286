import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Router, RouterLink } from "@angular/router";
import { Location } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { ConfirmDialogComponent } from "src/app/shared/dialogs/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "src/app/auth/services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FirebaseService } from "src/app/core/services/firebase.service";
import { PlatformService } from "src/app/core/services/platform.service";
import { firstValueFrom } from "rxjs";
import { Roles } from "src/app/shared/interfaces/user";

@Component({
  selector: "app-side-menu",
  standalone: true,
  imports: [CommonModule, RouterLink, TranslateModule, MatIconModule],
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent {
  @Output() toggleSubMenu: EventEmitter<any> = new EventEmitter();
  @Input() sideNavToggleBtn!: boolean;
  @Input() subMenu!: boolean;
  openFlag: boolean = false;
  routeTitle: string = "";
  route!: any;
  sideMenu = [
    {
      id: "home",
      title: "home",
      route: "/home",
      image: `../../../../assets/images/side-menu/home`,
      hasChildren: false,
      children: [] as any,
      condition: true,
    },
    {
      id: "users",
      title: "users",
      route: "/users",
      image: `../../../../assets/images/side-menu/consumer`,
      hasChildren: false,
      condition: true,
    },
    {
      title: "plates-ads",
      route: "/plates-ads/all-ads",
      image: `../../../../assets/images/side-menu/ads`,
      hasChildren: true,
      id: "plates-ads",
      children: [
        {
          title: "all-ads",
          route: "/plates-ads/all-ads",
        },
        {
          title: "ads-pending",
          route: "/plates-ads/ads-pending",
        },
        {
          title: "ads-rejected-deleted",
          route: "/plates-ads/ads-rejected-deleted",
        },
        {
          title: "ads-approved",
          route: "/plates-ads/ads-approved",
        },
        {
          title: "ads-reserved",
          route: "/plates-ads/ads-reserved",
        },
        {
          title: "ads-payment-pending",
          route: "/plates-ads/ads-payment-pending",
        },
        {
          title: "ads-document-pending",
          route: "/plates-ads/ads-document-pending",
        },
        {
          title: "ads-confirmation-pending",
          route: "/plates-ads/ads-confirmation-pending",
        },
        {
          title: "ads-fund-transfer-pending",
          route: "/plates-ads/ads-fund-transfer-pending",
        },
        {
          title: "ads-ended",
          route: "/plates-ads/ads-ended",
        },
        {
          title: "ads-suspended",
          route: "/plates-ads/ads-suspended",
        },
      ],
      condition: true,
    },
    {
      title: "requested-plates",
      route: "/requested-plates",
      image: `../../../../assets/images/side-menu/request`,
      hasChildren: false,
      condition: true,
    },
    {
      title: "mobile-num-ads",
      route: "/mobile-num-ads/all-ads",
      image: `../../../../assets/images/side-menu/mobile-num`,
      hasChildren: true,
      id: "mobile-num-ads",
      children: [
        {
          title: "all-ads",
          route: "/mobile-num-ads/all-ads",
        },
        {
          title: "ads-pending",
          route: "/mobile-num-ads/ads-pending",
        },
        {
          title: "ads-rejected-deleted",
          route: "/mobile-num-ads/ads-rejected-deleted",
        },
        {
          title: "ads-approved",
          route: "/mobile-num-ads/ads-approved",
        },
        {
          title: "ads-payment-pending",
          route: "/mobile-num-ads/ads-payment-pending",
        },
        {
          title: "ads-confirmation-pending",
          route: "/mobile-num-ads/ads-confirmation-pending",
        },
        {
          title: "ads-fund-transfer-pending",
          route: "/mobile-num-ads/ads-fund-transfer-pending",
        },
        {
          title: "ads-ended",
          route: "/mobile-num-ads/ads-ended",
        },
        {
          title: "ads-suspended",
          route: "/mobile-num-ads/ads-suspended",
        },
      ],
      condition: true,
    },
    {
      title: "social-account-ads",
      route: "/social-account-ads/all-ads",
      image: `../../../../assets/images/side-menu/social-account`,
      hasChildren: true,
      id: "social-account-ads",
      children: [
        {
          title: "all-ads",
          route: "/social-account-ads/all-ads",
        },
        {
          title: "ads-pending",
          route: "/social-account-ads/ads-pending",
        },
        {
          title: "ads-rejected-deleted",
          route: "/social-account-ads/ads-rejected-deleted",
        },
        {
          title: "ads-approved",
          route: "/social-account-ads/ads-approved",
        },
        {
          title: "ads-payment-pending",
          route: "/social-account-ads/ads-payment-pending",
        },
        {
          title: "ads-confirmation-pending",
          route: "/social-account-ads/ads-confirmation-pending",
        },
        {
          title: "ads-fund-transfer-pending",
          route: "/social-account-ads/ads-fund-transfer-pending",
        },
        {
          title: "ads-ended",
          route: "/social-account-ads/ads-ended",
        },
        {
          title: "ads-suspended",
          route: "/social-account-ads/ads-suspended",
        },
      ],
      condition: true,
    },
    {
      title: "domain-ads",
      route: "/domain-ads/all-ads",
      image: `../../../../assets/images/side-menu/domain`,
      hasChildren: true,
      id: "domain-ads",
      children: [
        {
          title: "all-ads",
          route: "/domain-ads/all-ads",
        },
        {
          title: "ads-pending",
          route: "/domain-ads/ads-pending",
        },
        {
          title: "ads-rejected-deleted",
          route: "/domain-ads/ads-rejected-deleted",
        },
        {
          title: "ads-approved",
          route: "/domain-ads/ads-approved",
        },
        {
          title: "ads-payment-pending",
          route: "/domain-ads/ads-payment-pending",
        },
        {
          title: "ads-confirmation-pending",
          route: "/domain-ads/ads-confirmation-pending",
        },
        {
          title: "ads-fund-transfer-pending",
          route: "/domain-ads/ads-fund-transfer-pending",
        },
        {
          title: "ads-ended",
          route: "/domain-ads/ads-ended",
        },
        {
          title: "ads-suspended",
          route: "/domain-ads/ads-suspended",
        },
      ],
      condition: true,
    },
    {
      title: "payments",
      route: "/payments",
      image: `../../../../assets/images/side-menu/payments`,
      hasChildren: false,
      condition: true,
    },
    {
      title: "settings",
      route: "/settings",
      image: `../../../../assets/images/side-menu/settings`,
      hasChildren: false,
      condition: this._auth.user.currentUser["roles"][0] == Roles.superAdminTitle,
    },
    {
      title: "pages",
      route: "/pages/about-app",
      image: `../../../../assets/images/side-menu/cms`,
      hasChildren: true,
      id: "pages",
      children: [
        {
          title: "about-app",
          route: "/pages/about-app",
        },
        {
          title: "terms-conditions",
          route: "/pages/terms-conditions",
        },
        {
          title: "privacy-policy",
          route: "/pages/privacy-policy",
        },
        {
          title: "faqs",
          route: "/pages/faqs",
        },
      ],
      condition: this._auth.user.currentUser["roles"][0] == Roles.superAdminTitle,
    },
  ];
  @Output() sideNavToggleBtnRes = new EventEmitter<boolean>();
  secondaryColor = "var(--secondary-color)";

  constructor(
    private _router: Router,
    location: Location,
    private _dialog: MatDialog,
    private _translate: TranslateService,
    private _auth: AuthService,
    private _snackBar: MatSnackBar,
    private _firebase: FirebaseService,
    public _platForm: PlatformService
  ) {
    this._router.events.subscribe((val) => {
      if (location.path() != "") {
        this.route = location.path();
      }
    });
  }

  //toggle function to open and close sub menu in side nav bar
  toggle(value: boolean, openFlag: boolean, title?: string) {
    if (value && this.routeTitle != title) {
      value = true;
      if (title) this.routeTitle = title;
    } else {
      value = false;
      this.routeTitle = "";
    }
    this.openFlag = value;
    this.subMenu = value;
    this.toggleSubMenu.emit(value);
  }

  // go to pages
  goTo(route: string) {
    // check when side nav is close close sub menu when route
    if (this.sideNavToggleBtn) {
      this.toggle(false, false);
    }
    this._router.navigateByUrl(route);
    this.sideNavToggleBtnRes.emit(false);
  }

  // logout
  async logout() {
    const isLogout = await firstValueFrom(
      this._dialog
        .open(ConfirmDialogComponent, {
          width: "26.25rem",
          data: {
            title: this._translate.instant("login.logout"),
            content: this._translate.instant("login.are-you-want-logout"),
            action: { accept: "yes", refuse: "cancel", color: this.secondaryColor },
          },
        })
        .afterClosed()
    );

    if (isLogout?.boolean) {
      this._snackBar
        .open("تم تسجيل الخروج", "", {
          panelClass: "success-snackbar",
          duration: 1500,
        })
        .afterDismissed()
        .subscribe(() => {
          this._firebase.logout().subscribe();
          this._auth.user.logout();
          this._router.navigate(["/login"]);
          this._dialog.closeAll();
        });
    }
  }
}
