import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "src/app/auth/services/auth.service";

export const SuperAdminGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const auth = inject(AuthService);
  if (auth.user.currentUser && auth.user.currentUser["roles"][0] == "superAdmin") {
    return true;
  } else {
    router.navigateByUrl("/home");
    return false;
  }
};
