import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const NotAuthGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  if (!localStorage.getItem('UserProfile') && !localStorage.getItem('Token')) {
    return true;
  } else {
    router.navigateByUrl('/home');
    return false;
  }
};
